<template>
  <div id="app">
    <header>
      <img :src="logoSrc" alt="Company Logo" />
    </header>

    <h1>Bitcoin Trading Calculator</h1>

    <!-- Calculator Form -->
    <div class="calculator">
      <!-- Current Exchange Rate Section -->
      <section>
        <h2>Current Exchange Rate</h2>
        <div
            style="display: flex; justify-content: space-between; align-items: center;"
        >
          <p>
            1 BTC = {{ exchangeRate ? exchangeRate : 'Loading...' }}
            {{ globalData.stableTender }}
          </p>
          <p>
            {{ countdown }}
            <button class="button-emoji" @click="refreshExchangeRate">🔁</button>
          </p>
        </div>
      </section>

      <!-- Stable Tender Selection -->
      <section>
        <h2>Stable Tender</h2>
        <div class="input-group">
          <div v-if="!isMobile">
            <div class="stable-tender-options">
              <label v-for="option in stableTenderOptions" :key="option">
                <input
                    type="radio"
                    :value="option"
                    v-model="globalData.stableTender"
                />
                {{ option }}
              </label>
            </div>
          </div>
          <div v-else>
            <div
                @click="isTenderOptionsVisible = !isTenderOptionsVisible"
                class="collapsible-header"
            >
              Selected Tender: {{ globalData.stableTender || 'Select Tender' }}
            </div>
            <div v-if="isTenderOptionsVisible" class="stable-tender-options">
              <label v-for="option in stableTenderOptions" :key="option">
                <input
                    type="radio"
                    :value="option"
                    v-model="globalData.stableTender"
                />
                {{ option }}
              </label>
            </div>
          </div>
        </div>
      </section>

      <!-- Trade Details -->
      <section>
        <h2>Trade Details</h2>

        <!-- Input Method Selection -->
        <div class="input-group">
          <label>Input Method:</label>
          <div class="stable-tender-options">
            <label>
              <input
                  type="radio"
                  value="btcAmount"
                  v-model="inputMethod"
              />
              Enter BTC Amount
            </label>
            <label>
              <input
                  type="radio"
                  value="amountPaid"
                  v-model="inputMethod"
              />
              Enter Amount Paid ({{ globalData.stableTender }})
            </label>
          </div>
        </div>

        <!-- Entry Price -->
        <div class="input-group">
          <label for="entryPrice"
          >Entry Price ({{ globalData.stableTender }}):</label
          >
          <input
              type="text"
              :value="globalData.entryPrice"
              @input="onNumberInput($event, 'entryPrice')"
              inputmode="decimal"
              pattern="[0-9]*[.,]?[0-9]*"
              required
          />
        </div>

        <!-- Conditional Inputs based on Input Method -->
        <div v-if="inputMethod === 'btcAmount'" class="input-group">
          <label for="cryptoAmount">Amount of Crypto Purchasing (BTC):</label>
          <input
              type="text"
              :value="globalData.cryptoAmountInput"
              @input="onNumberInput($event, 'cryptoAmountInput')"
              inputmode="decimal"
              pattern="[0-9]*[.,]?[0-9]*"
              required
          />
        </div>

        <div v-else-if="inputMethod === 'amountPaid'" class="input-group">
          <label for="amountPaid"
          >Amount Paid ({{ globalData.stableTender }}):</label
          >
          <input
              type="text"
              :value="globalData.amountPaidInput"
              @input="onNumberInput($event, 'amountPaidInput')"
              inputmode="decimal"
              pattern="[0-9]*[.,]?[0-9]*"
              required
          />
        </div>

        <!-- Commission -->
        <div class="input-group">
          <label for="commission">Commission (%):</label>
          <input
              type="text"
              :value="globalData.commission"
              @input="onNumberInput($event, 'commission')"
              inputmode="decimal"
              pattern="[0-9]*[.,]?[0-9]*"
              required
          />
        </div>

        <!-- Reactive Calculations -->
        <div class="output-left">
          <h2>Summary</h2>
          <p>
            <span
                class="tooltip"
                data-tooltip="Amount Paid = Entry Price × Crypto Amount"
            >Amount Paid ({{ globalData.stableTender }}):</span
            >
            <span class="value">{{ amountPaid.toFixed(2) }}</span>
          </p>
          <p>
            <span
                class="tooltip"
                data-tooltip="Net Crypto Received = Crypto Amount × (1 - Commission % / 100)"
            >Net Crypto Received (BTC):</span
            >
            <span class="value">{{ netCryptoReceived.toFixed(8) }}</span>
          </p>
          <p>
            <span
                class="tooltip"
                data-tooltip="Breakeven Price = Total Cost / [Net Crypto × (1 - Commission % / 100)]"
            >Breakeven Price ({{ globalData.stableTender }}):</span
            >
            <span class="value">{{ breakevenPrice.toFixed(2) }}</span>
          </p>
          <p>
            <span
                class="tooltip"
                data-tooltip="Amount Received = Net Crypto × Current Price × (1 - Commission % / 100)"
            >Amount Received at Current Price
              ({{ globalData.stableTender }}):</span
            >
            <span class="value">{{ amountAtCurrentPrice.toFixed(2) }}</span>
          </p>
          <p>
            <span
                class="tooltip"
                data-tooltip="Total Trade Movement = Amount Received at Current Price - Amount Paid"
            >Total Trade Movement ({{ globalData.stableTender }}):</span
            >
            <span class="value">{{ totalTradeMovement.toFixed(2) }}</span>
          </p>
        </div>
      </section>

      <!-- Stop Loss Inputs -->
      <section>
        <h2>
          <span
              class="tooltip"
              data-tooltip="Input multiple Stop Loss percentages and select one for risk management calculations."
          >Stop Loss</span
          >
        </h2>
        <div class="percentage-inputs">
          <div
              v-for="(percent, index) in stopLossPercents"
              :key="index"
              class="input-output-group"
          >
            <input
                type="text"
                :value="percent"
                @input="onSLTPInput($event, 'stopLossPercents', index)"
                inputmode="decimal"
                pattern="[0-9]*[.,]?[0-9]*"
            />
            <div class="output">
              <p>
                Exit Price ({{ globalData.stableTender }}):
                {{ stopLossPrices[index].price.toFixed(2) }}
              </p>
              <p>
                Amount: {{ stopLossPrices[index].amount.toFixed(2) }}
                {{ globalData.stableTender }}
              </p>
              <label>
                <input
                    type="radio"
                    :value="index"
                    v-model="selectedStopLossIndex"
                />
                Select
              </label>
            </div>
          </div>
        </div>
      </section>

      <!-- Take Profit Inputs -->
      <section>
        <h2>
          <span
              class="tooltip"
              data-tooltip="Input multiple Take Profit percentages and select one for risk management calculations."
          >Take Profit</span
          >
        </h2>
        <div class="percentage-inputs">
          <div
              v-for="(percent, index) in takeProfitPercents"
              :key="index"
              class="input-output-group"
          >
            <input
                type="text"
                :value="percent"
                @input="onSLTPInput($event, 'takeProfitPercents', index)"
                inputmode="decimal"
                pattern="[0-9]*[.,]?[0-9]*"
            />
            <div class="output">
              <p>
                Exit Price ({{ globalData.stableTender }}):
                {{ takeProfitPrices[index].price.toFixed(2) }}
              </p>
              <p>
                Amount: {{ takeProfitPrices[index].amount.toFixed(2) }}
                {{ globalData.stableTender }}
              </p>
              <label>
                <input
                    type="radio"
                    :value="index"
                    v-model="selectedTakeProfitIndex"
                />
                Select
              </label>
            </div>
          </div>
        </div>
      </section>

      <!-- Risk Management Section -->
      <section>
        <h2>Risk Management</h2>
        <div class="input-group">
          <label>Probability of Hitting Take Profit (%):</label>
          <input
              type="text"
              :value="takeProfitProbability"
              @input="onNumberInput($event, 'takeProfitProbability')"
              inputmode="decimal"
              pattern="[0-9]*[.,]?[0-9]*"
          />
        </div>
        <div class="output-left">
          <p>
            <span
                class="tooltip"
                data-tooltip="Expected Return = (Probability of Profit × Potential Profit) + (Probability of Loss × Potential Loss)"
            >Expected Return ({{ globalData.stableTender }}):</span
            >
            <span class="value">{{ expectedReturn.toFixed(2) }}</span>
          </p>
          <p>
            <span
                class="tooltip"
                data-tooltip="Risk-Reward Ratio = Potential Profit / Potential Loss"
            >Risk-Reward Ratio:</span
            >
            <span class="value">{{ riskRewardRatioDisplay }}</span>
          </p>
        </div>
      </section>
    </div>

    <!-- Theme Toggle -->
    <div class="theme-toggle">
      <button @click="toggleDarkMode">
        {{ isDarkMode ? '☀️' : '🌙' }}
      </button>
    </div>
  </div>
</template>

<script>
import { ref, computed, onMounted, onUnmounted, watch } from 'vue';
import { useGlobalDataStore } from './stores/globalData';
import axios from 'axios';
import logoLight from '@/assets/logo.png';
import logoDark from '@/assets/logo_dark.png';

export default {
  name: 'App',
  setup() {
    const globalData = useGlobalDataStore();

    // Initialize default values
    globalData.entryPrice = globalData.entryPrice || '';
    globalData.cryptoAmountInput = globalData.cryptoAmountInput || '';
    globalData.amountPaidInput = globalData.amountPaidInput || '';
    globalData.commission = globalData.commission || '';

    // Stable Tender Options
    const stableTenderOptions = ['USDC', 'USDT', 'USD'];

    // Detect if mobile
    const isMobile = ref(window.innerWidth <= 600);

    // Handle window resize
    const handleResize = () => {
      isMobile.value = window.innerWidth <= 600;
    };

    window.addEventListener('resize', handleResize);

    // Collapsible state
    const isTenderOptionsVisible = ref(false);

    // Input Method
    const inputMethod = ref('btcAmount');

    // Method to handle number input fields
    const onNumberInput = (event, field) => {
      let value = event.target.value;
      // Replace comma with dot
      value = value.replace(',', '.');
      // Remove any non-numeric characters except dot
      value = value.replace(/[^0-9.]/g, '');
      // Update the appropriate field
      if (field === 'takeProfitProbability') {
        takeProfitProbability.value = value;
      } else {
        globalData[field] = value;
      }
    };

    // Method to handle Stop Loss and Take Profit input fields
    const onSLTPInput = (event, arrayName, index) => {
      let value = event.target.value;
      // Replace comma with dot
      value = value.replace(',', '.');
      // Remove any non-numeric characters except dot
      value = value.replace(/[^0-9.]/g, '');
      // Update the percentage value as string
      if (arrayName === 'stopLossPercents') {
        stopLossPercents.value[index] = value;
      } else if (arrayName === 'takeProfitPercents') {
        takeProfitPercents.value[index] = value;
      }
    };

    // Computed cryptoAmount based on input method
    const cryptoAmount = computed(() => {
      const entryPriceValue = parseFloat(globalData.entryPrice);
      if (isNaN(entryPriceValue) || entryPriceValue === 0) {
        return 0;
      }
      if (inputMethod.value === 'btcAmount') {
        const cryptoAmountValue = parseFloat(globalData.cryptoAmountInput);
        if (isNaN(cryptoAmountValue)) {
          return 0;
        }
        return cryptoAmountValue;
      } else if (inputMethod.value === 'amountPaid') {
        const amountPaidValue = parseFloat(globalData.amountPaidInput);
        if (isNaN(amountPaidValue)) {
          return 0;
        }
        return amountPaidValue / entryPriceValue;
      }
      return 0; // Ensure a return value
    });

    // Reactive calculations
    const amountPaid = computed(() => {
      const entryPriceValue = parseFloat(globalData.entryPrice);
      if (isNaN(entryPriceValue) || entryPriceValue === 0) {
        return 0;
      }
      if (inputMethod.value === 'btcAmount') {
        const cryptoAmountValue = parseFloat(globalData.cryptoAmountInput);
        if (isNaN(cryptoAmountValue)) {
          return 0;
        }
        return entryPriceValue * cryptoAmountValue;
      } else if (inputMethod.value === 'amountPaid') {
        const amountPaidValue = parseFloat(globalData.amountPaidInput);
        if (isNaN(amountPaidValue)) {
          return 0;
        }
        return amountPaidValue;
      }
      return 0; // Ensure a return value
    });

    const netCryptoReceived = computed(() => {
      const cryptoAmountValue = cryptoAmount.value;
      const commissionValue = parseFloat(globalData.commission);
      if (isNaN(cryptoAmountValue) || isNaN(commissionValue)) {
        return 0;
      }
      const commissionDecimal = commissionValue / 100;
      return cryptoAmountValue * (1 - commissionDecimal);
    });

    const breakevenPrice = computed(() => {
      const commissionValue = parseFloat(globalData.commission);
      if (
          isNaN(amountPaid.value) ||
          isNaN(commissionValue) ||
          netCryptoReceived.value === 0
      ) {
        return 0;
      }
      const commissionDecimal = commissionValue / 100;

      // Calculate total cost
      const totalCost = amountPaid.value;

      // Calculate net crypto received
      const netCrypto = netCryptoReceived.value;

      // Breakeven price formula:
      // totalCost = breakevenPrice * netCrypto * (1 - commissionDecimal)
      const breakeven =
          totalCost / (netCrypto * (1 - commissionDecimal));

      return breakeven;
    });

    // Amount Received at Current Price
    const amountAtCurrentPrice = computed(() => {
      const netCrypto = netCryptoReceived.value;
      const currentPriceValue = parseFloat(exchangeRate.value);
      const commissionValue = parseFloat(globalData.commission);
      if (
          isNaN(netCrypto) ||
          isNaN(currentPriceValue) ||
          isNaN(commissionValue)
      ) {
        return 0;
      }
      const commissionDecimal = commissionValue / 100;
      return netCrypto * currentPriceValue * (1 - commissionDecimal);
    });

    // Total Trade Movement
    const totalTradeMovement = computed(() => {
      return amountAtCurrentPrice.value - amountPaid.value;
    });

    // Stop Loss and Take Profit Percentages
    const stopLossPercents = ref(['0.1', '0.5', '1.0']);
    const takeProfitPercents = ref(['0.5', '1.0', '2.0']);

    // Functions to calculate exit prices and amounts
    const stopLossPrices = computed(() => {
      return stopLossPercents.value.map((percent) => {
        const percentValue = parseFloat(percent);
        if (isNaN(percentValue)) {
          return { price: 0, amount: 0 };
        }
        return calculateExitPrice(percentValue, false);
      });
    });

    const takeProfitPrices = computed(() => {
      return takeProfitPercents.value.map((percent) => {
        const percentValue = parseFloat(percent);
        if (isNaN(percentValue)) {
          return { price: 0, amount: 0 };
        }
        return calculateExitPrice(percentValue, true);
      });
    });

    // Selected Stop Loss and Take Profit Indices
    const selectedStopLossIndex = ref(0);
    const selectedTakeProfitIndex = ref(0);

    // Calculate selected Stop Loss and Take Profit Prices
    const selectedStopLossPrice = computed(() => {
      return stopLossPrices.value[selectedStopLossIndex.value];
    });

    const selectedTakeProfitPrice = computed(() => {
      return takeProfitPrices.value[selectedTakeProfitIndex.value];
    });

    // Function to calculate exit price and amount
    const calculateExitPrice = (percent, isTakeProfit) => {
      const commissionValue = parseFloat(globalData.commission);
      if (
          isNaN(amountPaid.value) ||
          isNaN(commissionValue) ||
          netCryptoReceived.value === 0 ||
          isNaN(percent)
      ) {
        return { price: 0, amount: 0 };
      }
      const commissionDecimal = commissionValue / 100;
      const netCrypto = netCryptoReceived.value;
      const totalCost = amountPaid.value;

      const percentDecimal = percent / 100;
      const profitOrLossAmount =
          totalCost * percentDecimal * (isTakeProfit ? 1 : -1);

      const denominator = netCrypto * (1 - commissionDecimal);
      if (denominator === 0) {
        return { price: 0, amount: 0 };
      }

      const exitPrice = (totalCost + profitOrLossAmount) / denominator;
      const amount = profitOrLossAmount;

      if (!isFinite(exitPrice) || isNaN(exitPrice) || isNaN(amount)) {
        return { price: 0, amount: 0 };
      }

      return { price: exitPrice, amount: amount };
    };

    // Risk Management Variables
    const takeProfitProbability = ref('60'); // Default 60%

    const potentialProfit = computed(() => {
      if (!selectedTakeProfitPrice.value) {
        return 0;
      }
      return selectedTakeProfitPrice.value.amount;
    });

    const potentialLoss = computed(() => {
      if (!selectedStopLossPrice.value) {
        return 0;
      }
      return -selectedStopLossPrice.value.amount; // Negative value
    });

    const expectedReturn = computed(() => {
      const tpProbValue = parseFloat(takeProfitProbability.value);
      if (
          isNaN(tpProbValue) ||
          isNaN(potentialProfit.value) ||
          isNaN(potentialLoss.value)
      ) {
        return 0;
      }
      const tpProb = tpProbValue / 100;
      const slProb = 1 - tpProb;
      return tpProb * potentialProfit.value - slProb * potentialLoss.value;
    });

    const riskRewardRatio = computed(() => {
      if (potentialLoss.value === 0) {
        return null;
      }
      return Math.abs(potentialProfit.value / potentialLoss.value);
    });

    const riskRewardRatioDisplay = computed(() => {
      return riskRewardRatio.value !== null
          ? riskRewardRatio.value.toFixed(2)
          : 'N/A';
    });

    // Exchange Rate Data
    const exchangeRate = ref(null);
    const countdown = ref(25);
    const intervalId = ref(null);

    const fetchExchangeRate = async () => {
      try {
        if (!globalData.stableTender) {
          exchangeRate.value = null;
          return;
        }
        const response = await axios.get(
            'https://api.coinbase.com/v2/exchange-rates?currency=BTC'
        );
        const rates = response.data.data.rates;
        exchangeRate.value = rates[globalData.stableTender];
        countdown.value = 25; // Reset countdown
      } catch (error) {
        console.error('Error fetching exchange rate:', error);
      }
    };

    const refreshExchangeRate = () => {
      fetchExchangeRate();
    };

    onMounted(() => {
      fetchExchangeRate();
      intervalId.value = setInterval(() => {
        if (countdown.value > 0) {
          countdown.value--;
        } else {
          fetchExchangeRate();
        }
      }, 1000); // Update every second

      window.addEventListener('resize', handleResize);

      // Set initial theme based on browser preference
      const prefersDarkScheme = window.matchMedia(
          '(prefers-color-scheme: dark)'
      ).matches;
      isDarkMode.value = prefersDarkScheme;
    });

    onUnmounted(() => {
      clearInterval(intervalId.value);
      window.removeEventListener('resize', handleResize);
    });

    watch(
        () => globalData.stableTender,
        () => {
          fetchExchangeRate();
        }
    );

    // Dark Mode Control
    const isDarkMode = ref(false);

    const toggleDarkMode = () => {
      isDarkMode.value = !isDarkMode.value;
    };

    const logoSrc = computed(() => (isDarkMode.value ? logoDark : logoLight));

    watch(
        () => isDarkMode.value,
        (newValue) => {
          if (newValue) {
            document.body.classList.add('dark-mode');
            document.body.classList.remove('light-mode');
          } else {
            document.body.classList.remove('dark-mode');
            document.body.classList.add('light-mode');
          }
        },
        { immediate: true }
    );

    return {
      globalData,
      amountPaid,
      netCryptoReceived,
      breakevenPrice,
      amountAtCurrentPrice,
      totalTradeMovement,
      stopLossPercents,
      takeProfitPercents,
      stopLossPrices,
      takeProfitPrices,
      selectedStopLossIndex,
      selectedTakeProfitIndex,
      selectedStopLossPrice,
      selectedTakeProfitPrice,
      stableTenderOptions,
      exchangeRate,
      countdown,
      refreshExchangeRate,
      isMobile,
      isTenderOptionsVisible,
      isDarkMode,
      toggleDarkMode,
      // Risk Management
      takeProfitProbability,
      expectedReturn,
      riskRewardRatioDisplay,
      logoSrc,
      onNumberInput,
      onSLTPInput,
      // Input Method
      inputMethod,
    };
  },
};
</script>

<style>
/* General styles */

body {
  margin: 0;
  font-family: 'Times New Roman', Times, serif;
  background-color: #f0f4f8;
  color: #000;
  transition: background-color 0.3s, color 0.3s;
}

#app {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

header {
  text-align: center;
  padding: 20px 0;
}

header img {
  max-width: 200px;
}

h1 {
  text-align: center;
  color: navy;
}

h2 {
  color: navy;
}

.calculator {
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
}

.calculator section {
  margin-bottom: 30px;
}

.calculator h2 {
  border-bottom: 1px solid #ddd;
  padding-bottom: 10px;
}

.input-group {
  margin-bottom: 15px;
}

.input-group label {
  display: block;
  color: #555;
  margin-bottom: 5px;
}

.input-group input[type='text'] {
  width: 100%;
  padding: 8px;
  margin-top: 5px;
  box-sizing: border-box;
}

.stable-tender-options {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}

.stable-tender-options label {
  display: flex;
  align-items: center;
}

.stable-tender-options input {
  margin-right: 5px;
}

.collapsible-header {
  cursor: pointer;
  background-color: #e0e0e0;
  padding: 8px;
  border-radius: 4px;
}

.percentage-inputs,
.selection-inputs {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}

.selection-option {
  padding: 10px 15px;
  border: 1px solid #ccc;
  cursor: pointer;
  border-radius: 5px;
  text-align: center;
  user-select: none;
}

.selection-option.selected {
  background-color: #007bff;
  color: #fff;
  border-color: #007bff;
}

.input-output-group {
  display: flex;
  flex-direction: column;
  border: 1px solid #ddd;
  padding: 10px;
  border-radius: 5px;
}

.output {
  margin-top: 10px;
  text-align: left;
}

.output-left {
  margin-top: 10px;
  text-align: left;
}

.output p {
  margin: 20px 0;
}

.output p span.value {
  padding-left: 10px;
}

.result-block {
  margin-bottom: 10px;
}

.button-emoji {
  background: none;
  border: none;
  font-size: 1em;
  cursor: pointer;
}

.button-emoji:hover {
  transform: scale(1.1);
  background: none;
}

.error-message {
  color: #8a0707; /* Blood red */
}

.success-message {
  color: #50c878; /* Emerald green */
}

.theme-toggle {
  text-align: center;
  margin-top: 20px;
}

.theme-toggle button {
  background: none;
  border: none;
  font-size: 2em;
  cursor: pointer;
}

.theme-toggle button:hover {
  transform: scale(1.1);
  background: none;
}

.tooltip {
  position: relative;
  cursor: help;
  border-bottom: 1px dashed;
}

.tooltip::after {
  content: attr(data-tooltip);
  position: absolute;
  left: 50%;
  bottom: 125%; /* Show above the text */
  transform: translateX(-50%);
  background-color: #333;
  color: #fff;
  padding: 5px;
  border-radius: 5px;
  white-space: pre-line;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.3s;
  width: 220px;
  text-align: center;
  z-index: 100;
}

.tooltip:hover::after {
  opacity: 1;
}

@media (max-width: 600px) {
  #app {
    padding: 10px;
  }

  .percentage-inputs,
  .selection-inputs {
    flex-direction: column;
  }

  .input-output-group {
    flex-direction: column;
  }

  .stable-tender-options {
    flex-direction: column;
  }
}

@media (min-width: 601px) {
  .percentage-inputs,
  .selection-inputs {
    flex-direction: row;
  }

  .input-output-group {
    flex-direction: column;
    align-items: center;
    flex: 1;
  }
}

/* Dark mode styles */
body.dark-mode {
  background-color: #121212;
  color: #FFD700; /* Warm yellow */
}

body.dark-mode .calculator {
  background-color: #1E1E1E;
}

body.dark-mode h1,
body.dark-mode h2,
body.dark-mode button {
  color: #ff9601; /* Blood orange */
}

body.dark-mode .input-group label {
  color: #cccccc;
}

body.dark-mode .collapsible-header {
  background-color: #333;
}

body.dark-mode .tooltip::after {
  background-color: #eee;
  color: #333;
}

/* Adjust grey colors in dark mode */
body.dark-mode .input-group label {
  color: #cccccc;
}

body.dark-mode .selection-option {
  border-color: #555;
  color: #ccc;
}

body.dark-mode .selection-option.selected {
  background-color: #FF4500;
  color: #fff;
  border-color: #FF4500;
}
</style>