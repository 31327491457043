// src/stores/globalData.js

import { defineStore } from 'pinia';
import { ref } from 'vue';

export const useGlobalDataStore = defineStore('globalData', () => {
    const entryPrice = ref('');
    const cryptoAmount = ref('');
    const commission = ref(0.1);
    const stableTender = ref('USDC');

    return {
        entryPrice,
        cryptoAmount,
        commission,
        stableTender,
    };
});